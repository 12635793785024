<template>
   <div>
      <!-- Breadcrumb start -->
      <section class="bradcrumb_sec">
         <div class="container-fluid custome_container">
            <div class="row justify-content-center">
               <div class="col-md-11">
                  <div class="bread_crumb_box mb_22">
                     <h2 class="heading_1 font_bold mb_23">Couches</h2>
                     <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                           <li class="breadcrumb-item"><router-link class="navbar-brand m-0 p-0" :to="{ name: 'user_home' }">Home</router-link></li>
                           <li class="breadcrumb-item">
                              <router-link :to="{ name: 'work_from_home' }">Working From Home</router-link>
                           </li>
                           <li class="breadcrumb-item">
                              <router-link :to="{ name: 'home_essentials' }">Home Essentials</router-link>
                           </li>
                           <li class="breadcrumb-item">
                              <router-link :to="{ name: 'home_essentials_seating' }">Seating</router-link>
                           </li>
                           <li class="breadcrumb-item">
                              <router-link :to="{ name: 'home_essentials_desktop' }">Desktop</router-link>
                           </li>
                           <li class="breadcrumb-item active" aria-current="page">Couches</li>
                        </ol>
                     </nav>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- Breadcrumb end -->
      <!-- pagination Starts -->
      <section class="pagination_sec">
         <div class="pagination-nav">
            <div class="container-fluid custome_container">
               <div class="row justify-content-center">
                  <div class="col-md-11">
                     <div class="row justify-content-between">
                        <div class="col-md-12">
                           <nav aria-label="Page navigation example">
                              <ul class="pagination justify-content-between mb_32">
                                 <li class="page-item">                                   
                                    <router-link class="page-link border-0 text_black"  aria-label="Previous" :to="{ name: 'home_essentials_desktop' }">
                                       <span aria-hidden="true">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="40" height="29.631" viewBox="0 0 43.575 29.631">
                                          <g id="Left_arrow" data-name="Left arrow" transform="translate(2.75 3.967)">
                                            <path id="Path_30" data-name="Path 30" d="M1755.8,353.578l-10.914,10.889,10.937,10.965" transform="translate(-1744.886 -353.656)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
                                            <path id="Path_31" data-name="Path 31" d="M1755.8,353.578l-10.914,10.889,10.937,10.965" transform="translate(-1728.886 -353.656)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
                                          </g>
                                       </svg>
                                          Previous
                                       </span>
                                    </router-link>
                                 </li>
                                 <li class="page-item">
                                    <router-link class="page-link border-0 text_black" :to="{ name: 'home_essentials_household' }" aria-label="Next">
                                       <span aria-hidden="true" class="font_bold">
                                          <span class="text_primary">Up Next<span class="mobile_hide">:</span></span>
                                          <span class="mobile_hide"> Household Ergo</span>
                                         <svg xmlns="http://www.w3.org/2000/svg" width="33.575" height="29.631" viewBox="0 0 33.575 29.631" style="margin-left:5px;">
                                             <g id="Right_arrow" data-name="Right arrow" transform="translate(-12884.354 20078.389)">
                                               <path id="Path_30" data-name="Path 30" d="M1744.908,353.578l10.914,10.889-10.937,10.965" transform="translate(11159.357 -20428.078)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
                                               <path id="Path_31" data-name="Path 31" d="M1744.908,353.578l10.914,10.889-10.937,10.965" transform="translate(11143.357 -20428.078)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
                                             </g>
                                          </svg>
                                       </span>
                                    </router-link>
                                 </li>
                              </ul>
                           </nav>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- pagination Ends -->

      <!-- Set Yourself up for success Starts -->
      <section class="image_text_sec bg_grey pt_50 pb_50 mt_30">
         <div class="container-fluid custome_container">
            <div class="row justify-content-center">
               <div class="col-md-11">
                  <div class="row">
                     <div class="col-md-6">
                        <div class="image_left_side">
                           <img src="../assets/images/home-essential-couches/Bg.png" alt="img">
                        </div>
                     </div>
                     <div class="col-md-6">
                        <div class="image_text_right_side">
                           <h2 class="mb_22">Are you a couch slouch?</h2>
                           <p class="mb_40">
                              While it’s best to work at a desk or table top, you may occasionally want to plop down on your couch or soft chair to get some work done. And that is fine! But just make sure  you are not a slouch on that couch!</p>
                           <p class="mb_20">
                              Here are some tips to help you:
                           </p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      <div class="text-center down_to_section newarrow">
            
               <a   v-scroll-to="{
     el: '#claim_space',
     duration: 500,
     easing: 'linear',
     offset: -350,
 }">
                              <svg xmlns="http://www.w3.org/2000/svg" width="50.132" height="35.219" viewBox="0 0 57.132 61.219">
  <g id="Down_arrow" data-name="Down arrow" transform="translate(-20020.894 -12891.354) rotate(90)">
    <path id="Path_30" data-name="Path 30" d="M1744.937,353.578l24.648,24.591-24.7,24.763" transform="translate(11180.239 -20427.715)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
    <path id="Path_31" data-name="Path 31" d="M1744.937,353.578l24.648,24.591-24.7,24.763" transform="translate(11150.357 -20427.715)" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5"/>
  </g>
</svg>
            </a>
         </div>
      </section>
        <!-- image text end -->
        <!-- Claim Your Space start -->
        <section id="claim_space" class="claim_space_sec py_100 my_14">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-md-11 col-xl-9 col-12">
                  <div class="claim_box d-flex">
                     <div class="claim_text_image_box">
                        <h4 class="text_primary font_bold font_size_32 mb_0"> Coffee 
</h4>
                        <h4 class="text_primary font_bold font_size_32 mb_26">table trap </h4>
                        <img src="../assets/images/home-essential-couches/coffee.svg" alt="img">
                     </div>
                     <div class="claim_text_box">
                        <p class="text_balticsea mb_34">
                           <strong>Do you use your coffee table as your desktop? </strong>
                        </p>

                        <p class="text_silverchalice mb_44">
                           If yes, you should know that this can promote serious slouching and can wreak havoc on your neck, back, and shoulders. Try using your TV as your monitor and place your keyboard and mouse on a lap desk (store-bought or homemade) or just use your laptop on a lap desk, preferably with enough room for an external mouse.
                        </p>
                     </div>
                  </div>
                  <div class="claim_box d-flex">
                     <div class="claim_text_image_box">
                        <h4 class="text_primary font_bold font_size_32 mb_0"> Throw
</h4>
                        <h4 class="text_primary font_bold font_size_32 mb_26">pillows</h4>
                        <img src="../assets/images/DIY/pillow.svg" alt="img">
                     </div>
                     <div class="claim_text_box">
                        <p class="text_black mb_34">
                          While couches are meant for comfortable lounging, they don’t always foster good sitting posture for working. If the seat of your couch is too deep, try placing throw pillows or a rolled-up towel behind you for extra back support. Folded towels and small pillows also work wonders for added foot support if your feet don’t reach the floor.
                        </p>

                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- Adjust your chair end -->

      <!-- more WFH tips start  -->
      <section class="more_lab_tips bg_grey pt_42">
         <div class="container">
            <div class="row justify-content-center ">
               <div class="col-md-12 col-xl-11 px_20">
                  <div class="row">
                     <div class="col-md-12">
                        <h4 class="heading_4 font_bold mb-0">More Work from Home Tips</h4>
                     </div>
                  </div>
                  <div class="row">
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'home_essentials' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left">
                                 <img src="../assets/images/home-essentials/home.png" alt="img" class="img-fluid">
                              </div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Home Essentials </h6>
                                 <p>Create a viable place to work anywhere in your home. </p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'home_essentials_seating' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left">
                                 <img src="../assets/images/home-essentials/seating.png" alt="img" class="img-fluid">
                              </div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Seating</h6>
                                 <p>Sit pretty and have the best posture around.</p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'home_essentials_desktop' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left">
                                 <img src="../assets/images/home-essentials/desktop.png" alt="img" class="img-fluid">
                              </div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Desktop</h6>
                                 <p>Enjoy ergo-friendly work surfaces in your home. </p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'home_essentials_couches' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left">
                                 <img src="../assets/images/home-essentials/couches.png" alt="img" class="img-fluid">
                              </div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Couches</h6>
                                 <p>Don’t be a couch slouch! We’ll show you how. </p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'home_essentials_household' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left">
                                 <img src="../assets/images/home-essentials/diy.png" alt="img" class="img-fluid">
                              </div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Household Ergo</h6>
                                 <p>Household items can keep you in good ergo standing.</p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                     <div class="col-sm-12 col-md-6 col-xl-4">
                        <router-link :to="{ name: 'home_stretches' }" class="more_lab_tip_link">
                           <div class="d-flex setup-guide">
                              <div class="flex-shrink-0 media-left">
                                 <img src="../assets/images/home-essentials/streches.png" alt="img" class="img-fluid">
                              </div>
                              <div class="flex-grow-1 ml_20 media-right">
                                 <h6 class="font_size_22 text_black mb_10">Home Stretches</h6>
                                 <p>Take a break with these home stretches to feel great.</p>
                              </div>
                           </div>
                        </router-link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- more WFH tips end  -->
   </div>
</template>

<script>
   export default {
      name: "home_essentials_couches"
   }
</script>